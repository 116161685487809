import classes from './style.module.css'
const TxSucessModal = ({ transactionCompleteResponse }) => {
  console.log(transactionCompleteResponse, "12345")
  return (
    <>
      <div className={classes.popupBackground}>
        <div className={classes.popupContent}>
          <h4>Transaction Completed</h4>
          <hr />
          <div style={{ display: 'flex', flexDirection: 'column', fontSize: '1em', wordWrap: 'break-all', alignItems: 'flex-start' }}>
            <p>Transaction Status: {transactionCompleteResponse?.Status}</p>
            <p style={{ wordBreak: 'break-all' }}>Transaction Hash: {transactionCompleteResponse?.TransactionHash}</p>
            <p>Amount: {transactionCompleteResponse?.Amount}</p>
            <p>Date and Time: {transactionCompleteResponse?.RecTimeStamp}</p>
          </div>
          <hr />
          <h4 style={{ color: "green", fontSize: '18px', fontWeight: "bold", wordBreak: 'break-word' }} >Your Transaction Is Completed, Now You Can Login Your Account On Browser</h4>
        </div >
      </div>
    </>
  )
}
export default TxSucessModal;
